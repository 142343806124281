<template>
  <el-drawer
    size="1200px"
    custom-class="chart-drawer"
    :visible="visible"
    @open="handleOpen"
    @close="handleClose"
  >
    <template #title>
      <!-- 头部下拉搜索 -->
      <select-tree
        v-if="visible"
        :currentRow="currentRow"
        :list="list"
        :total="total"
        :pageSize="pageSize"
        :initPageAfter="pageAfter"
        :dateStart="defDateStart"
        :dateEnd="defDateEnd"
        :fields="initColumnProps"
        @post-sel-node="getSelNode"
      ></select-tree>
      <span style="flex:1;padding-left:10px;color:#333;font-size:14px" v-if="currentRow">当前{{currentRow.level == 'campaign'?'系列':currentRow.level == 'adset'?'广告组':currentRow.level == 'ad'?'广告':''}}ID：{{currentRow.id}}</span>
    </template>
    <div class="content-area pr">
      <el-tabs class="total-tab" v-model="activeTab" type="border-card">
        <!-- <el-tab-pane label="趋势分析" name="trend">
          <trend-pane
            v-if="activeTab == 'trend'"
            :dateRange="dateRange"
            :curSelNode="curSelNode"
            :getSummaries="getSummaries"
            :cellFormat="cellFormat"
            :chainStart="chainStart"
            :isCompare="isCompare"
          ></trend-pane>
        </el-tab-pane>
        <el-tab-pane label="国家分析" name="country">
          <country-pane
            v-if="activeTab == 'country'"
            :dateRange="dateRange"
            :curSelNode="curSelNode"
            :getSummaries="getSummaries"
            :cellFormat="cellFormat"
          ></country-pane>
        </el-tab-pane>
        <el-tab-pane label="人口分析" name="populace">
          <populace-pane
            v-if="activeTab == 'populace'"
            :dateRange="dateRange"
            :curSelNode="curSelNode"
            :getSummaries="getSummaries"
            :cellFormat="cellFormat"
          ></populace-pane>
        </el-tab-pane>
        <el-tab-pane label="版位分析" name="platform_position">
          <plat-pane
            v-if="activeTab == 'platform_position'"
            :dateRange="dateRange"
            :curSelNode="curSelNode"
            :getSummaries="getSummaries"
            :cellFormat="cellFormat"
          ></plat-pane>
        </el-tab-pane>
        <el-tab-pane label="设备平台" name="device_platform">
          <device-pane
            v-if="activeTab == 'device_platform'"
            :dateRange="dateRange"
            :curSelNode="curSelNode"
            :getSummaries="getSummaries"
            :cellFormat="cellFormat"
          ></device-pane>
        </el-tab-pane> -->
        <el-tab-pane
          v-for="(item, index) in tabs"
          :key="index"
          :label="item.label"
          :name="item.value"
        >
          <keep-alive>
            <components
              v-if="activeTab == item.value"
              :is="activeTabComponent"
              :dateRange="dateRange"
              :curSelNode="curSelNode"
              :getSummaries="getSummaries"
              :cellFormat="cellFormat"
              :chainStart="chainStart"
              :isCompare="isCompare"
            >
            </components>
          </keep-alive>
        </el-tab-pane>
      </el-tabs>
      <!-- 对比日期组件 -->
      <div class="pa date-wrap" style="right:0;top:0;">
        <!-- v-if="visible" //避免切换不同行的图表时date-picker组件mounted中initeDateRange不更新 -->
        <compare-date
          v-if="visible"
          ref="compareDateRef"
          :activeTab="activeTab"
          :initDateRange="[defDateStart, defDateEnd]"
          :currentDateRange="dateRange"
          :isCompare="isCompare"
          @change-current-date="handleChangeCurrentDate"
          @post-compare-status="getCompareStatus"
          @post-chain-start="getChainStart"
        ></compare-date>
      </div>
    </div>
  </el-drawer>
</template>

<script>
// table单元格数据格式化
import { formatedCellValue } from "./cellOperate";
// 组件
import selectTree from "./selectTree";
import compareDate from "./compareDate";
import cusTable from "@/views/components/cusTable";
import navMenu from "@/views/components/navMenu";
import cusColumnDialog from "@/views/adManagement/components/cusColumnDialog/index";
import downLoad from "@/views/adManagement/components/tableTopBar/downLoad.vue";
import trendPane from "./pane/trendPane";
import countryPane from "./pane/countryPane";
import populacePane from "./pane/populacePane";
import platPane from "./pane/platPane";
import devicePane from "./pane/devicePane";

import { mapGetters } from "vuex";

export default {
  components: {
    selectTree,
    compareDate,
    cusTable,
    navMenu,
    cusColumnDialog,
    downLoad,
    trendPane,
    countryPane,
    populacePane,
    platPane,
    devicePane
  },
  props: {
    // 初始日期范围
    defDateStart: {
      type: String,
      default: () => ""
    },
    defDateEnd: {
      type: String,
      default: () => ""
    },
    visible: {
      type: Boolean,
      default: () => false
    },
    currentRow: {
      type: Object,
      default: () => {}
    },
    pageSize: {
      type: [Number, String]
    },
    list: {
      type: Array,
      default: () => []
    },
    pageAfter: {
      type: String,
      default: () => ""
    },
    total: {
      type: Number,
      default: 100
    },
    fields: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // 选中广告系列-广告组-广告
      curSelNode: {},
      // 日期范围
      dateRange: [],
      // 对比
      isCompare: false,
      chainStart: "",
      // 当前tab
      activeTab: "trend",
      tabs: [
        { label: "趋势分析", value: "trend" },
        { label: "国家分析", value: "country" },
        { label: "人口分析", value: "populace" },
        { label: "版位分析", value: "platform_position" },
        { label: "设备平台", value: "device_platform" }
      ],
      // 平台切换
      navData: {
        navMenu: [
          { id: "device_platform", name: "设备平台数据报表" },
          { id: "publisher_platform", name: "平台数据报表" }
        ],
        activeNavIndex: "device_platform",
        bgc: "",
        mode: "horizontal",
        customClass: "plat-nav"
      }
    };
  },
  computed: {
    ...mapGetters("chartDrawer", ["filteredFields"]),
    // selectTree中使用
    initColumnProps() {
      return this.fields.map(item => item.keyName);
    },
    cellFormat() {
      return function(v, desc, prop, otherOption) {
        let format = this.$store.getters.toFormatedMoney(v, true).formated;
        return formatedCellValue(v, desc, prop, otherOption, format);
      };
    },
    activeTabComponent() {
      return (
        (this.activeTab == "platform_position"
          ? "plat"
          : this.activeTab == "device_platform"
          ? "device"
          : this.activeTab) + "-pane"
      );
    }
  },
  methods: {
    // 合计行格式化
    getSummaries({ columns, data }) {
      if (data.length) {
        let sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "总计";
            return;
          }
          let values = data.map(item => Number(item[column.property]));
          if (!values.every(value => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
          }
          let format = this.$store.getters.toFormatedMoney(sums[index], true)
            .formated;
          let desc = "";
          let specProps = ["cost_per_action_type", "object_actions"];
          if (specProps.some(s => s == column.property)) {
            desc =
              data[0][column.property + "_desc"] &&
              data[0][column.property + "_desc"];
          }
          sums[index] = formatedCellValue(
            sums[index],
            desc,
            column.property,
            { fields: this.fields },
            format
          );
        });
        return sums;
      } else {
        return [];
      }
    },
    getSelNode(obj) {
      this.curSelNode = { ...obj };
    },
    handleOpen() {
      this.activeTab = "trend";
    },
    handleClose() {
      this.$emit("close-drawer");
    },
    // 日期改变
    handleChangeCurrentDate(val) {
      this.dateRange = [...val];
      // 时间对比
      if (this.isCompare) {
        this.chainStart = this.$refs.compareDateRef.chainStart;
      }
    },
    // 对比
    getCompareStatus(status) {
      this.isCompare = status;
    },
    getChainStart(val) {
      // console.log("1-getChainStart(val)", val);
      if (this.isCompare) {
        this.chainStart = val;
      }
      // console.log("2-this.chainStart", this.chainStart);
    }
  },
  watch: {
    // 监听点击行内图表时携带过来的初始展示字段
    fields: {
      async handler(val) {
        // await this.$store.dispatch("chartDrawer/setFields", { fields: val });
        let arr = this.filteredFields.slice(0, 2);
        this.$store.dispatch("chartDrawer/setShowFields", { fields: arr });
      }
    },
    visible: {
      handler(val) {
        if (val) {
          this.dateRange = [this.defDateStart, this.defDateEnd]; //避免第一次加载图表组件时获取不到dateStart
          this.curSelNode = { ...this.currentRow };
        }
      }
    },
    activeTab: {
      handler(val) {
        this.isCompare = false;
        this.navData.activeNavIndex = "device_platform";
      }
    },
    // 对比
    isCompare(status, oldStatus) {
      let arr = [];
      if (status) {
        this.chainStart = this.$refs.compareDateRef.chainStart;
        arr = this.filteredFields.slice(0, 1);
      } else {
        arr = this.filteredFields.slice(0, 2);
        // 对比选中时对比的起始时间恢复初始状态
        let chainStart = this.$refs.compareDateRef.chainDateRange(
          this.dateRange
        )[0];
        this.$refs.compareDateRef.chainStart = chainStart;
        this.chainStart = chainStart;
      }
      this.$store.dispatch("chartDrawer/setShowFields", { fields: arr });
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.chart-drawer {
  @extend .bg-theme;
  padding: 16px;
  .el-drawer__header {
    padding: 0 0 0 11px;
    @extend .justify-btw;
    @extend .mb-0;
  }
  .el-drawer__header > :first-child {
    flex: 0 0 auto;
  }
  .el-drawer__body {
    overflow: auto;
  }
  .content-area {
    margin: 16px 0;
    .el-tabs {
      .el-tabs__item {
        margin-right: 8px;
      }
    }
    .total-tab {
      border: none;
      box-shadow: none;
      & > .el-tabs__header {
        @extend .bg-theme;
        border: none;
        .el-tabs__item {
          color: $theme-color !important;
          background-color: #ececef;
          &.is-active {
            @extend .bg-f;
            border-right-color: #fff !important;
            border-left-color: #fff !important;
          }
        }
      }
    }
    .gender-age-tab {
      &.el-tabs--card > .el-tabs__header {
        border-bottom: 1px solid #1890ff;
        .el-tabs__nav {
          border: none;
        }
        .el-tabs__item {
          border-right: 1px solid #e4e7ed;
          border-top: 1px solid #e4e7ed;
          &:first-child {
            border-left: 1px solid #e4e7ed;
          }
          &.is-active {
            border: 1px solid #1890ff;
            border-bottom-color: #fff;
          }
        }
      }
    }
  }

  .blank {
    height: 16px;
  }
  .table-area {
    padding: 0 24px 24px;
    .title {
      @extend .f-16;
      color: $theme-color;
      div span {
        @extend .f-12;
      }
    }
    .downLoad {
      .el-button {
        font-size: 16px;
      }
      .el-progress-circle {
        position: relative;
        top: 2px;
        & > svg path:first-child {
          stroke: $theme-color;
        }
      }
    }

    .plat-nav {
      .el-menu-item {
        font-size: 16px;
        color: $theme-color;
        height: auto;
        line-height: initial;
        padding: 0;
        margin-right: 54px;
      }
    }
  }
  .report-table {
    .el-table__row:nth-child(2n) {
      background: #fff;
    }
    .el-table__fixed-footer-wrapper tr {
      @extend .bg-theme;
    }
  }
}
.sel-drop {
  color: $theme-color;
  .model-p {
    line-height: 32px;
    span:last-child {
      padding: 0 11px;
      margin-right: 75px;
    }
  }
  .small-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}
</style>