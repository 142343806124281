<template>
  <div class="pane-content bg-f">
    <div class="chart-area py-16 px-20">
      <p class="title mb-16">数据分析</p>
      <div class="dp-flex align-item-c">
        <!-- 展示指标 -->
        <show-indicator></show-indicator>
      </div>
      <!-- chart -->
      <device-chart
        v-if="(hasData && deviceRes.length) || platRes.length"
        :colors="colors"
        :deviceRes="deviceRes"
        :platRes="platRes"
        :devicexAxisData="xAxisData(deviceRes, deviceMark)"
        :platxAxisData="xAxisData(platRes, platMark)"
        :fields="showFields"
        :deviceMark="deviceMark"
        :platMark="platMark"
        :titles="navData.navMenu.map(item => item.name)"
      ></device-chart>
      <div
        v-else
        class="dp-flex align-item-c justify-c"
        style="color:#909399;font-size:14px;height:300px;"
      >
        暂无数据
      </div>
    </div>
    <div class="blank bg-theme"></div>
    <!-- table 报表 -->
    <div class="table-area px-20">
      <div class="title py-16 dp-flex justify-btw align-item-c">
        <!-- 平台切换 -->
        <nav-menu :data="navData" @selectedNav="selectedNav"></nav-menu>
        <div class="dp-flex align-item-c cursor-pointer">
          <div class="downLoad mr-10">
            <down-load
              :downLoadParams="downLoadParams"
              tipContent="点击下载报表"
            ></down-load>
          </div>
          <!-- <p @click="openCusColumn" class="dp-flex align-item-c">
            <i class="el-icon-s-grid"></i><span>列</span>
          </p> -->
        </div>
      </div>
      <!-- table -->
      <cus-table
        ref="tableRef"
        :tableParams="tableParams"
        :tableData="
          navData.activeNavIndex == 'device_platform' ? deviceRes : platRes
        "
        :colData="colData(fixedFields)"
        :cuscolOtherOption="{ fields: filteredFields }"
        :getSummaries="getSummaries"
        :showSummary="true"
      >
        <template v-slot:colslot="{ scope, prop, otherOption }">
          <!-- <div v-if="prop == 'product_report'">
            <ad-cell-product-report
              :value="scope"
              :key="scope.row.level + scope.row.id + scope.row.name"
            ></ad-cell-product-report>
          </div>
          <div v-else-if="prop != 'budget'"> -->
          <div v-if="prop != 'budget'">
            {{
              cellFormat(
                scope.row[prop],
                scope.row[prop + "_desc"],
                prop,
                otherOption
              )
            }}
          </div>
        </template>
      </cus-table>
      <!-- 定制列 -->
      <cus-column-dialog
        :visible="cusColumnVisible"
        :initCheckedColumns="filteredFields.map(item => item.keyName)"
        :isSearchOpt="false"
        :hasFilterFields="true"
        @close-dialog="cusColumnVisible = false"
        @post-cus-columns="handleGetCusColumns"
      ></cus-column-dialog>
    </div>
  </div>
</template>

<script>
import showIndicator from "../showIndicator";
import deviceChart from "../chart/deviceChart";
import downLoad from "@/views/adManagement/components/tableTopBar/downLoad.vue";
import cusColumnDialog from "@/views/adManagement/components/cusColumnDialog/index";
import cusTable from "@/views/components/cusTable";
import navMenu from "@/views/components/navMenu";
import { breakdown } from "@/api/adManagement";
import { mapState, mapGetters } from "vuex";
// 经营评估
import AdCellProductReport from "@/views/adManagement/components/table/AdCellProductReport";
export default {
  components: {
    showIndicator,
    downLoad,
    cusColumnDialog,
    cusTable,
    navMenu,
    deviceChart,
    AdCellProductReport
  },
  props: {
    // 时间范围
    dateRange: {
      type: Array,
      default: () => []
    },
    curSelNode: {
      type: Object,
      default: () => {}
    },
    getSummaries: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    cellFormat: {
      type: Function,
      default: () => {
        return () => {};
      }
    }
  },
  data() {
    return {
      chartData: [],
      cusColumnVisible: false,
      tableParams: {
        className: "report-table",
        maxHeight: "400px"
      },
      // 平台切换
      navData: {
        navMenu: [
          { id: "device_platform", name: "设备平台数据报表" },
          { id: "publisher_platform", name: "平台数据报表" }
        ],
        activeNavIndex: "device_platform",
        bgc: "",
        mode: "horizontal",
        customClass: "plat-nav"
      },
      deviceRes: [],
      platRes: [],
      hasData: false
    };
  },
  computed: {
    ...mapState("chartDrawer", ["colors", "showFields"]),
    ...mapGetters("chartDrawer", ["filteredFields"]),
    // 下载数据
    downLoadParams() {
      let obj = {
        account_id: this.$store.getters.accountId,
        action_attribution_windows: [],
        after: "",
        date_start: this.dateRange[0],
        date_end: this.dateRange[1],
        fields: this.colData(this.fixedFields).map(item => item.prop),
        filtering: [],
        object_filtering: [],
        keyword: "",
        level: this.curSelNode.level,
        page_length: 20,
        sort: [],
        ids: [this.curSelNode.id]
      };
      return obj;
    },
    fixedFields() {
      return {
        label:
          this.navData.activeNavIndex == "device_platform"
            ? "设备平台"
            : "平台",
        prop: this.navData.activeNavIndex,
        fixed: "left",
        width: "100px"
      };
    },
    colData() {
      return function(fixedFields) {
        let arr = this.filteredFields.map(item => {
          return {
            label: item.name,
            prop: item.keyName
          };
        });
        arr.forEach(item => {
          if (item.label && item.label.length <= 3) {
            item.minWidth = "100px";
          } else {
            item.minWidth = "115px";
          }
        });
        arr.unshift(fixedFields);
        // console.log("computed-colData", arr);
        return arr;
      };
    },
    createTableData() {
      return this.chartData;
    },
    deviceMark() {
      return this.navData.navMenu[0].id;
    },
    platMark() {
      return this.navData.navMenu[1].id;
    },
    xAxisData() {
      return function(data, mark) {
        return data.map(item => item[mark]);
      };
    }
  },
  methods: {
    // 平台切换
    selectedNav(val) {
      this.navData.activeNavIndex = val;
      // let index = this.navData.navMenu.findIndex(item => item.id == val);
      // this.renderTable(this[`tableData${index + 1}`]);
    },
    breakdown(mark) {
      this.$showLoading();
      let params = {
        account_id: this.$store.getters.accountId,
        action_attribution_windows: [],
        date_start: this.dateRange[0],
        date_end: this.dateRange[1],
        fields: this.filteredFields.map(item => item.keyName),
        level: this.curSelNode.level,
        breakdowns: [mark],
        ids: [this.curSelNode.id]
      };
      return breakdown(JSON.stringify(params)).then(res => {
        this.$hideLoading();
        if (res.code == 0) {
          // this.chartData = res.data.data.length ? [...res.data.data] : [];
          // this.xAxisData = this.chartData.map(item => item[mark]);
          return res.data.data;
        }
      });
    },
    openCusColumn() {
      this.cusColumnVisible = true;
    },
    async handleGetCusColumns(arr) {
      // await this.$store.dispatch("chartDrawer/setFields", { fields: arr });
      let arr2 = [];
      arr2 = this.filteredFields.slice(0, 2);
      await this.$store.dispatch("chartDrawer/setShowFields", {
        fields: arr2
      });
      this.getDoubleData();
    },
    async getDoubleData() {
      this.deviceRes = await this.breakdown(this.deviceMark);
      this.platRes = await this.breakdown(this.platMark);
      this.hasData = true;
    }
  },
  mounted() {
    this.getDoubleData();
  },
  watch: {
    dateRange: {
      handler(val) {
        // console.log("w-dateRange");
        if (val.length) {
          this.getDoubleData();
        }
      },
      deep: true
    },
    curSelNode: {
      handler(val) {
        // console.log("w-curSelNode");
        this.getDoubleData();
      },
      deep: true
    }
  }
};
</script>

<style>
</style>