<template>
  <div class="pane-content bg-f">
    <div class="chart-area py-16 px-20">
      <p class="title mb-16">数据分析</p>
      <div class="dp-flex align-item-c">
        <!-- 展示指标 -->
        <show-indicator></show-indicator>
      </div>
      <!-- chart -->
      <country-chart
        v-if="chartData.length"
        :colors="colors"
        :chartData="chartData"
        :xAxisData="xAxisData"
        :fields="showFields"
        :mark="mark"
      ></country-chart>
      <div
        v-else
        class="dp-flex align-item-c justify-c"
        style="color:#909399;font-size:14px;height:300px;"
      >
        暂无数据
      </div>
    </div>
    <div class="blank bg-theme"></div>
    <!-- table 报表 -->
    <div class="table-area px-20">
      <div class="title py-16 dp-flex justify-btw align-item-c">
        <span>数据报表</span>
        <div class="dp-flex align-item-c cursor-pointer">
          <div class="downLoad mr-10">
            <down-load
              :downLoadParams="downLoadParams"
              tipContent="点击下载报表"
            ></down-load>
          </div>
          <!-- <p @click="openCusColumn" class="dp-flex align-item-c">
            <i class="el-icon-s-grid"></i><span>列</span>
          </p> -->
        </div>
      </div>
      <!-- table -->
      <cus-table
        ref="tableRef"
        :tableParams="tableParams"
        :tableData="createTableData"
        :colData="colData(fixedFields)"
        :cuscolOtherOption="{ fields: filteredFields }"
        :getSummaries="getSummaries"
        :showSummary="true"
      >
        <template v-slot:colslot="{ scope, prop, otherOption }">
          <!-- <div v-if="prop == 'product_report'">
            <ad-cell-product-report-v2
              :value="scope"
              :key="scope.row.level + scope.row.id + scope.row.name"
            ></ad-cell-product-report-v2>
          </div>
          <div v-else-if="prop != 'budget'"> -->
          <div v-if="prop != 'budget'">
            {{
              cellFormat(
                scope.row[prop],
                scope.row[prop + "_desc"],
                prop,
                otherOption
              )
            }}
          </div>
        </template>
      </cus-table>
      <!-- 定制列 -->
      <cus-column-dialog
        :visible="cusColumnVisible"
        :initCheckedColumns="filteredFields.map(item => item.keyName)"
        :isSearchOpt="false"
        :hasFilterFields="true"
        @close-dialog="cusColumnVisible = false"
        @post-cus-columns="handleGetCusColumns"
      ></cus-column-dialog>
    </div>
  </div>
</template>

<script>
import showIndicator from "../showIndicator";
import countryChart from "../chart/countryChart";
import downLoad from "@/views/adManagement/components/tableTopBar/downLoad.vue";
import cusColumnDialog from "@/views/adManagement/components/cusColumnDialog/index";
import cusTable from "@/views/components/cusTable";
import { breakdown } from "@/api/adManagement";
import { mapState, mapGetters } from "vuex";
// 经营评估
import AdCellProductReportV2   from "@/views/adManagement/components/table/AdCellProductReportV2";
export default {
  components: {
    showIndicator,
    countryChart,
    downLoad,
    cusColumnDialog,
    cusTable,
    AdCellProductReportV2
  },
  props: {
    // 时间范围
    dateRange: {
      type: Array,
      default: () => []
    },
    curSelNode: {
      type: Object,
      default: () => {}
    },
    getSummaries: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    cellFormat: {
      type: Function,
      default: () => {
        return () => {};
      }
    }
  },
  data() {
    return {
      mark: "country",
      chartData: [],
      xAxisData: [],
      cusColumnVisible: false,
      tableParams: {
        className: "report-table",
        maxHeight: "400px"
      }
    };
  },
  computed: {
    ...mapState("chartDrawer", ["colors", "showFields"]),
    ...mapGetters("chartDrawer", ["filteredFields"]),
    // 下载数据
    downLoadParams() {
      let obj = {
        account_id: this.$store.getters.accountId,
        action_attribution_windows: [],
        after: "",
        date_start: this.dateRange[0],
        date_end: this.dateRange[1],
        fields: this.colData(this.fixedFields).map(item => item.prop),
        filtering: [],
        object_filtering: [],
        keyword: "",
        level: this.curSelNode.level,
        page_length: 20,
        sort: [],
        ids: [this.curSelNode.id]
      };
      return obj;
    },
    fixedFields() {
      return {
        label: "国家",
        prop: "country",
        fixed: "left",
        width: "120px"
      };
    },
    colData() {
      return function(fixedFields) {
        console.log(this.filteredFields);
        let arr = this.filteredFields.map(item => {
          return {
            label: item.name,
            prop: item.keyName
          };
        });
        arr.forEach(item => {
          if (item.label && item.label.length <= 3) {
            item.minWidth = "100px";
          } else {
            item.minWidth = "115px";
          }
        });
        arr.unshift(fixedFields);
        // console.log("computed-colData", arr);
        return arr;
      };
    },
    createTableData() {
      return this.chartData;
    }
  },
  methods: {
    handleCommand(c, index) {
      this.$emit("indicator-command", c, index);
    },
    breakdown() {
      this.$showLoading();
      let params = {
        account_id: this.$store.getters.accountId,
        action_attribution_windows: [],
        date_start: this.dateRange[0],
        date_end: this.dateRange[1],
        fields: this.filteredFields.map(item => item.keyName),
        level: this.curSelNode.level,
        breakdowns: [this.mark],
        ids: [this.curSelNode.id]
      };
      return breakdown(JSON.stringify(params)).then(res => {
        this.$hideLoading();
        if (res.code == 0) {
          this.chartData = res.data.data.length ? [...res.data.data] : [];
          this.xAxisData = this.chartData.map(item => item[this.mark]);
          // console.log("this.chartData", this.chartData);
        }
      });
    },
    openCusColumn() {
      this.cusColumnVisible = true;
    },
    async handleGetCusColumns(arr) {
      // await this.$store.dispatch("chartDrawer/setFields", { fields: arr });
      let arr2 = [];
      arr2 = this.filteredFields.slice(0, 2);
      await this.$store.dispatch("chartDrawer/setShowFields", {
        fields: arr2
      });
      this.breakdown();
    }
  },
  mounted() {
    this.breakdown();
  },
  watch: {
    dateRange: {
      handler(val) {
        // console.log("w-dateRange");
        if (val.length) {
          this.breakdown();
        }
      },
      deep: true
    },
    curSelNode: {
      handler(val) {
        // console.log("w-curSelNode");
        this.breakdown();
      },
      deep: true
    }
  }
};
</script>

<style>
</style>